<template>
    <div class="card card-default">
        <div class="card-header bg-gray-lighter text-bold">{{$t('common.view.settings.address.heading')}}</div>
        <div class="card-body">
            <b-row>
                <b-col sm="12">

                    <b-btn v-if="!deliveryAddress" @click="deliveryAddress = true"><i class="fad fa-truck-loading mr-1"></i> {{$t('common.view.settings.address.button_delivery_address')}}</b-btn>

                    <div v-show="deliveryAddress">
                        <h3 class="text-center">{{ $t('common.view.settings.address.title_delivery') }}</h3>
                        <address-form entity-slot="delivery" @address-detected="deliveryAddress = true"/>
                    </div>

                </b-col>
                <b-col sm="12">

                    <h3 class="text-center">{{ $t('common.view.settings.address.title_residency') }}</h3>

                    <address-form entity-slot="residency"/>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
    import AddressForm from "@/components/Forms/AddressForm.vue";

    export default {
        components: {
            AddressForm
        },
        data() {
            return {
                deliveryAddress: false,
            }
        }
    }
</script>